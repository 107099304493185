import React from 'react';
import { chain } from 'lodash';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

const ValuesGraph = ({
  values,
  width,
  isAdmin,
}) => {
  if (width === 0) {
    return null;
  }
  const {
    percentile: percentiles,
    conversion: conversions,
  } = values;

  const margin = {
    left: rem(9),
    right: rem(9),
    top: rem(1),
    bottom: rem(1),
  };
  const barHeight = rem(1.8); //bar height has to be at least 2rem to make room for the padding
  const barPadding = rem(0.18);
  const height = conversions.length * (barHeight + barPadding) + margin.top + margin.bottom;
  const delay = 0;
  const duration = 1000;

  const combind = chain(Array(conversions.length))
    .map((sc, i) => ({
      ...conversions[i],
      norm: undefined,
      scaleType: undefined,
      rawScore: parseInt(conversions[i].rawScore, 10),
      conversion: conversions[i].norm,
      percentile: percentiles[i].norm,
    }))
    .sortBy((a) => (-Math.abs(a.conversion)))
    .map((sc, i) => ({
      ...sc,
      sorted: i,
    }))
    .sortBy((a) => (a.ranking))
    .value();

  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right ]);

  const y = d3
    .scaleBand()
    .domain(conversions.map((con) => (con.name)))
    .range([margin.bottom, height - margin.top])
    .round(false)
    .padding(barPadding / rem(1));

  const bottomAxis = d3.axisBottom(x);

  const G = combind.map((c) => {
    const yPos = y(c.name);
    let start = x(50);
    let w = x(c.percentile) - start;
    if (c.conversion <= 0) {
      start = x(c.percentile);
      w = x(50) - start;
    }
    return (
      <g key={`valuesBar_${c.id}`}>
        <text
          x={x(0)}
          y={yPos}
          dy={rem(1)}
          ref={(node) => {
            if (node) {
              const bbox = node.getBBox();
              d3
                .select(node)
                .attr(
                  'transform',
                  `translate(-${bbox.width + rem(0.25)}, 0)`,
                )
                .style('fill', Colors.grey)
                .style('font-size', rem(1))
                .style('font-weight', 'bold');
            }
          }}
          transform={`translate(-${margin.left}, 0)`}
        >
          {c.left.toUpperCase()}
        </text>
        <rect
          ref={(node) => {
            d3
              .select(node)
              .attr('width', 0)
              .transition()
              .delay(delay)
              .ease(d3.easeLinear)
              .duration(duration)
              .attr('x', start)
              .attr('width', w);
          }}
          x={x(50)}
          y={yPos}
          width={0}
          height={y.bandwidth()}
          fill={c.sorted < 3 ? Colors.red : Colors.lightGrey}
        />
        {
          isAdmin ? (
            <text
              x={x(c.percentile)}
              y={yPos}
              dy={rem(0.9)}
              ref={(node) => {
                if (node && c.conversion > 0) {
                  const bbox = node.getBBox();
                  d3
                    .select(node)
                    .attr(
                      'x',
                      x(c.percentile)
                      - bbox.width,
                    );
                }
                if (node) {
                  d3
                    .select(node)
                    .transition()
                    .delay(delay + duration)
                    .ease(d3.easeLinear)
                    .duration(duration);
                }
              }}
            >
              {c.conversion}
            </text>
          ) : ''
        }
        <text
          x={x(100)}
          y={yPos}
          dy={rem(1)}
          transform="translate(10, 0)"
          ref={(node) => {
            if (node) {
              d3
                .select(node)
                .style('fill', Colors.grey)
                .style('font-size', rem(1))
                .style('font-weight', 'bold');
            }
          }}
        >
          {c.right.toUpperCase()}
        </text>
      </g>
    );
  });

  const CentralAxis = (
    <g>
      <line
        stroke={Colors.grey}
        strokeWidth={1}
        x1={x(50)}
        x2={x(50)}
        y1={y(0)}
        y2={height - margin.top - margin.bottom}
        transform={`translate(0, ${margin.top})`}
      />
    </g>
  );

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      {CentralAxis}
      {G}
      <g
        ref={(node) => d3.select(node).call(bottomAxis)}
        transform={`translate(0, ${y(conversions[conversions.length - 1].name) + barHeight})`}
      />
    </svg>
  );
};

export default ValuesGraph;
