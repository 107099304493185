import { connect } from 'react-redux';

import DiscoverAbilitiesProfile from '../components/DiscoverAbilitiesProfile';

const mapStateToProps = (state) => {
  const {
    assessment: {
      results: {
        abilities,
      },
    },
  } = state;

  return {
    abilities,
  };
};

const mapDispatchToProps = () => ({});

const DiscoverAbilitiesProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverAbilitiesProfile);

export default DiscoverAbilitiesProfileContainer;
