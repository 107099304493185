import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';

import { scrollTo } from '../../utils/scrollTo';
import {
  useResize,
} from '../../utils/sizing';
import ApiImage from './ApiImage';
import { CenteredSpinner } from './Spinner';

import * as Colors from './Colors';
import {
  MediumButton as Button,
} from './Button';
import {
  H1,
  H2 as H2Default,
} from './Headers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.white};
  width: 100%;
  flex: 1 1 auto;
  max-width: 1400px;
  margin: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 2 2 auto;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 8rem;
  @media (max-width: 768px) {
    padding-right: 1rem;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    padding-right: 1rem;
  }
`;

const H2 = styled(H2Default)`
  font-size: 1rem;
  margin: 2.5rem 0 0 0;
`;

const P = styled.p`
  display: inline;
  margin: 0;
  line-height: 1.25rem;
`;

const Ul = styled.ul`
  margin: 0;
`;

const TextContainer = styled.div`
  margin: .5rem 0;
`;

const NavButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-top: 3rem;
  position: sticky;
  align-self: flex-start;
  top: 0;
`;

const NavButton = styled(Button)`
  margin: .5rem 0;
`;

const XButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const JobTitle = styled(H1)`
  display: flex;
  flex: 1 1 auto;
  margin: .25rem;
`;

const JobTasks = styled(Ul)`
  padding: 0 0 0 1rem;
`;

const JobTask = styled.li`
  padding: .25rem 0;
`;

const SkillsList = styled(Ul)`
  @media(min-width: 768px) {
    column-count: 2;
  }
  padding: 0 0 0 1rem;
`;

const Skill = styled.li`
  margin: .25rem;
  width: 100%;
`;

const WhatNowUl = styled(Ul)`
  margin: 0;
  padding: 0 0 0 1rem;
`;

const WhatNowLi = styled.li`
`;

const JobCodesUl = styled(Ul)`
  maring: 0;
  padding: 0 0 0 1rem;
`;
const JobCodeLi = styled.li``;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

const ONET = ({ code }) => {
  const formattedCode = `${code.substring(0, 2)}-${code.substring(2, 6)}.${code.substring(6)}`;
  return (
    <a
      rel="noreferrer"
      target="_blank"
      href={`http://www.onetonline.org/link/summary/${formattedCode}`}
    >
      O*Net #{formattedCode}
    </a>
  );
};

ONET.propTypes = {
  code: PropTypes.string.isRequired,
};

const JobDetails = ({
  loadJob,
  loaded,
  jobTitle,
  toggleFavorite,
  description,
  image,
  tasks,
  training,
  education,
  courses,
  skills,
  salaryText,
  outlook,
  whatNow,
  jobCodes,
  favoriteJob,
  allFavoriteJobs,
}) => {
  let content = (<CenteredSpinner />);
  const { id } = useParams();
  const contentRef = useRef();
  const wrapperRef = useRef();
  const tasksRef = useRef();
  const trainingRef = useRef();
  const educationRef = useRef();
  const skillsRef = useRef();
  const salaryRef = useRef();
  const outlookRef = useRef();
  const [ width ] = useResize(contentRef);

  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    loadJob({ id });
  }, [id]);

  if (loaded) {
    content = (
      <>
        <div
          ref={tasksRef}
        >
          <H2>JOB TASKS</H2>
          <JobTasks>
            {
              tasks.map((task) => (
                <JobTask key={`task_${uuid()}`}>{task}</JobTask>
              ))
            }
          </JobTasks>
        </div>
        <TextContainer
          ref={trainingRef}
        >
          <H2>Training</H2>
          <P>
            {
              training.join(' ')
            }
          </P>
        </TextContainer>
        <TextContainer>
          <H2>Courses</H2>
          <P>
            {courses.map((course) => (course.name.charAt(0).toUpperCase() + course.name.slice(1))).join(', ')}
          </P>
        </TextContainer>
        <TextContainer
          ref={educationRef}
        >
          <H2>Education</H2>
          <P>
            {education.map((edu) => (edu.name)).join(', ')}
          </P>
        </TextContainer>
        <div
          ref={skillsRef}
        >
          <H2>Skills</H2>
          <SkillsList>
            {skills.map((skill) => (<Skill key={`skill_${uuid()}`}>{skill}</Skill>))}
          </SkillsList>
        </div>
        <TextContainer
          ref={salaryRef}
        >
          <H2>Salary</H2>
          <P>{salaryText}</P>
        </TextContainer>
        <TextContainer
          ref={outlookRef}
        >
          <H2>Outlook</H2>
          <P>{outlook}</P>
        </TextContainer>
        <H2>Additional Information</H2>
        <WhatNowUl>
          {
            whatNow.map((w) => (
              <WhatNowLi key={`whatnow_${uuid()}`}>
                <a href={w.href} target="_blank" rel="noreferrer">{w.text}</a>
              </WhatNowLi>
            ))
          }
        </WhatNowUl>
        <H2>Cross References</H2>
        <JobCodesUl>
          {
            jobCodes.map((code) => {
              switch (code.type) {
                case 'onet': {
                  return <JobCodeLi key={`code_${uuid()}`}><ONET code={code.value} /></JobCodeLi>;
                }
                case 'noc': {
                  return (
                    <JobCodeLi key={`code_${uuid()}`}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://noc.esdc.gc.ca/Structure/NocProfile?objectId=${encodeURIComponent(code.nocHash)}`}
                      >
                        NOC: #{code.value}
                      </a>
                    </JobCodeLi>
                  );
                }
                case 'australian': {
                  return (
                    <JobCodeLi key={`code_${uuid()}`}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`http://joboutlook.gov.au/occupation.aspx?code=${code.value}`}
                      >
                        Australian Job Outlook #{code.value}
                      </a>
                    </JobCodeLi>
                  );
                }
                default: {
                  return <JobCodeLi key={`code_${uuid()}`}>{code.value}</JobCodeLi>;
                }
              }
            })
          }
        </JobCodesUl>
      </>
    );
  }

  return (
    <Wrapper ref={wrapperRef}>
      <NavButtonContainer>
        {
          image ? (
            <ApiImage
              src={image.replace('/static', '')}
              width="411"
              height="319"
            />
          ) : ''
        }
        <NavButton
          onClick={() => scrollTo(tasksRef, { customOffset: -50 })}
        >
          Tasks
        </NavButton>
        <NavButton
          onClick={() => scrollTo(trainingRef, { customOffset: -50 })}
        >
          Training
        </NavButton>
        <NavButton
          onClick={() => scrollTo(educationRef, { customOffset: -50 })}
        >
          Education
        </NavButton>
        <NavButton
          onClick={() => scrollTo(skillsRef, { customOffset: -50 })}
        >
          Skills
        </NavButton>
        <NavButton
          onClick={() => scrollTo(salaryRef, { customOffset: -50 })}
        >
          Salary
        </NavButton>
        <NavButton
          onClick={() => scrollTo(outlookRef, { customOffset: -50 })}
        >
          Outlook
        </NavButton>
      </NavButtonContainer>
      <ContentWrapper ref={contentRef} width={width}>
        <XButtonContainer>
          <JobTitle>{jobTitle.toUpperCase()}</JobTitle>
          <Button
            link
            onClick={() => onClose()}
          >
            Back
          </Button>
        </XButtonContainer>
        {description}
        {content}
        <ButtonContainer>
          <Button
            style={{ marginTop: '1rem' }}
            onClick={() => {
              toggleFavorite({ job: { id: parseInt(id, 10) }, favoriteJobs: allFavoriteJobs });
              onClose();
            }}
          >
            {
              favoriteJob !== null && favoriteJob !== undefined
                ? 'Remove From Favorites'
                : 'Add to My Favorites'
            }
          </Button>
          <Button
            link
            onClick={() => onClose()}
          >
            Back
          </Button>
        </ButtonContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

JobDetails.propTypes = {
  loaded: PropTypes.bool,
  jobTitle: PropTypes.string,
  description: PropTypes.string,
  toggleFavorite: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.string),
  training: PropTypes.arrayOf(PropTypes.string),
  education: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  skills: PropTypes.arrayOf(PropTypes.string),
  salaryText: PropTypes.string,
  outlook: PropTypes.string,
  whatNow: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  })),
  jobCodes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  })),
};

JobDetails.defaultProps = {
  jobTitle: '',
  description: '',
  loaded: false,
  tasks: [],
  training: [],
  education: [],
  courses: [],
  skills: [],
  salaryText: '',
  outlook: '',
  whatNow: [],
  jobCodes: [],
};

export default JobDetails;
