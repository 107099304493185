/* eslint max-len:0 */
import React, { useRef } from 'react';
import styled from 'styled-components';

import { chain } from 'lodash';

import {
  Colors,
  InterestsGraph,
  MediumButton as Button,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const BlueText = styled.span`
  color: ${Colors.blue};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const P = styled.p`
  margin: 0.5rem 1.5rem;
`;

const DiscoverText = [
  'These careers involve planning and conducting research within applied mathematics, medicine, physical sciences, and other STEM-related fields.',
  'These careers generally involve assisting with laboratory research or operating technical facilities and equipment.',
  'These careers involve the engineering and design of vehicles, buildings and infrastructure, machines, and computer software.',
  'These careers involve working with one’s hands in a skilled trade. Construction, manufacturing, installation, and maintenance-related occupations fall under this cluster.',
  'These careers involve the preparation and packaging of foods and beverages, or the production of clothing and other textile products.',
  'These careers are typically performed outside. Occupations include tending to animals, managing plants/flora, and cultivating and gathering crops and other natural resources.',
  'These careers generally involve positions of high responsibility related to the administration, organization, and efficient functioning of businesses and government bureaus. Occupations include accounting, management, and business promotion.',
  'These careers involve sales, promotion, and marketing. They also include financial and organizational business activities related to business promotion.',
  'These careers involve the recording, posting, and filing of business records. Most positions require high levels of attention to detail, accuracy, orderliness, and speed.',
  'These careers involve language skills associated with the written and oral communication of knowledge and ideas.',
  'These careers are centered around the expression of creative talent, including positions in design, fine art, and the performing arts.',
  'These careers involve the application of artistic skill and sensibility in fields such as photography, graphic arts, and design.',
  'These careers involve positions of high responsibility in caring for the personal needs and welfare of others in fields such as social service, health, and education.',
  'These careers involve providing service to support the tastes, desires, and welfare of others in the fields of personal, social, or health related services. These careers can also include providing protection and transportation.',
];

const DiscoverInterests = ({
  interests,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const top3 = chain(interests)
    .sortBy((a) => (a.norm))
    .reverse()
    .take(3)
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="interests" />
      <P>Your top three intrests areas are:</P>
      <P><BlueText>{top3[0].name}</BlueText>: {DiscoverText[top3[0].id - 1]}</P>
      <P><BlueText>{top3[1].name}</BlueText>: {DiscoverText[top3[1].id - 1]}</P>
      <P><BlueText>{top3[2].name}</BlueText>: {DiscoverText[top3[2].id - 1]}</P>
      <InterestsGraph
        interests={interests}
        width={width}
      />
      <P>
        A percentile score is printed inside each bar. These scores approximate
        your position on each scale as compared to other people at your
        educational level and reported gender. If your score is near 50, about
        half (50%) of others fall below you. The higher your score, the greater
        your interest is compared to others.
      </P>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/abilities">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverInterests;
