import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useLocation,
} from 'react-router-dom';

import {
  FullGraph,
  MediumButton as Button,
  CareerWheel,
  ScrollToSelf,
  Colors,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import DiscoverAbilities from '../containers/DiscoverAbilitiesProfile';

const P = styled.p`
  margin: 1rem 1rem 1rem 1rem;
`;

const Content = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  margin-top: 10rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between
`;

const DiscoverCareerWheel = ({
  type,
  rankedClusters,
  results,
}) => {
  const ref = React.useRef();
  const [ width ] = useResize(ref);
  const location = useLocation();
  return (
    <>
      <DiscoverAbilities />
      <Content
        ref={ref}
      >
        { location.pathname.includes('fullgraph') ? <ScrollToSelf /> : null }
        <CareerWheel
          type={type}
          height={400}
        />
        <P>
          Now that we have looked at your Values, Interests, and Abilities, let&apos;s
          put all your scores together and find some careers that match you.
        </P>
        <FullGraph
          clusters={rankedClusters}
          clusterMax={results.clusters.max}
          width={width}
        />
        <P>
          Should we talk about how someone will need to improve their abilities
          if they do not have a ability, but have Interests and Values?
        </P>
        <P>
          Should we describe what these clusters are?
        </P>
        <ButtonContainer>
          <Button
            to="/dashboard/examinee/discover/abilityprofile"
          >
            Back
          </Button>
          <Button
            to="/dashboard/examinee/discover/cluster/0"
          >
            Next
          </Button>
        </ButtonContainer>
      </Content>
    </>
  );
};

DiscoverCareerWheel.propTypes = {
  /** The type of career wheel. */
  type: PropTypes.oneOf([ 'cluster', 'professional', 'spanish' ]),
  /** The callback function to move to the next component */
};

DiscoverCareerWheel.defaultProps = {
  type: 'cluster',
};

export default DiscoverCareerWheel;
