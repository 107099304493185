import React, { useRef } from 'react';
import styled from 'styled-components';

import {
  Colors,
  AbilityCareerProfile,
  MediumButton as Button,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const P = styled.p``;

const DiscoverAbilities = ({
  abilities,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="abilities" />
      <AbilityCareerProfile
        abilities={abilities}
        width={width}
      />
      <P>
        We have mapped your Abilities scores onto the 14 VIA System career
        clusters. Your score is marked by a yellow or gray circle. The light
        shaded bars represent fixed cutoff scores, NOT your own results. If your
        score is above a cluster’s cutoff score, your circle will turn yellow
        and a plus (+) is printed next to the cluster’s name. Scoring above a
        cutoff score indicates that you are on the right track for success in
        that particular cluster.
      </P>
      <P>
        Each score is derived from your Abilities responses, so be sure to
        consider whether other qualities you know about yourself are consistent
        with this profile. It is important to focus on the individual distance of
        your scores from the cutoffs. If one of your scores falls below a
        cutoff, don’t be discouraged! These results are a snapshot of your
        abilities at this moment. If you are just below a cutoff score, you may
        only need to make a few small improvements. If you are interested in
        that career cluster and genuinely motivated to succeed, you can always
        look to improve your baseline abilities by taking classes or receiving
        training.
      </P>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/abilities">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/fullgraph">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverAbilities;
